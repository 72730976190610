import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css' // 全局样式
import './assets/fonts/iconfont.css' // 字体样式
import TreeTable from 'vue-table-with-tree-grid'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.config.productionTip = false

Vue.prototype.alertMessage = function (message, type) {
  this.$message({
    message,
    type,
    duration: 1000
  })
}

Vue.component('tree-table', TreeTable) // 注册为全局可用的组件

Vue.use(VueQuillEditor)

// 定义全局的时间过滤器
Vue.filter('dateFormat', function (value) {
  const date = new Date(value)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1 + '').padStart(2, '0')
  const day = (date.getDate() + '').padStart(2, '0')
  const hour = (date.getHours() + '').padStart(2, '0')
  const minute = (date.getMinutes() + '').padStart(2, '0')
  const second = (date.getSeconds() + '').padStart(2, '0')
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
})

Vue.filter('floatFormat', function (value) {
  return Math.round(value * 100) / 100
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
