import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('../components/Login')
const Home = () => import('../components/Home')
const Welcome = () => import('../components/Welcome')
const Category = () => import('../components/goods/Category')
const Real = () => import('../components/statistics/Real')
const Cnzz = () => import('../components/statistics/Cnzz')
const Cost = () => import('../components/statistics/Cost')
const Site = () => import('../components/statistics/Site')
const Goods = () => import('../components/goods/Goods')
const GoodsAdd = () => import('../components/goods/goodsChildComponents/GoodsAdd')
const GoodsComment = () => import('../components/goods/goodsChildComponents/GoodsComment')
/* const Users = () => import('../components/user/Users')
const Rights = () => import('../components/rights/Rights')
const Roles = () => import('../components/rights/Roles')
const Category = () => import('../components/goods/Category')
const Params = () => import('../components/goods/Params')
const GoodsList = () => import('../components/goods/List')
const GoodsAdd = () => import('../components/goods/listChildComponents/ListAdd')
const Report = () => import('../components/report/Report') */
const Order = () => import('../components/order/Order')
const OrderSetting = () => import('../components/order/Setting')
const OrderDetail = () => import('../components/order/orderChildComponents/OrderDetail')
const OrderDelivery = () => import('../components/order/orderChildComponents/OrderDelivery')
const Trade = () => import('../components/order/Trade')
const ExpDelivery = () => import('../components/order/ExpDelivery')
const User = () => import('../components/user/User')
const SystemUser = () => import('../components/user/SystemUser')
const QrCode = () => import('../components/user/QrCode')
const Shop = () => import('../components/goods/Shop')
const Attribute = () => import('../components/goods/Attribute')
const Term = () => import('../components/goods/Term')
const Sign = () => import('../components/task/Sign')
const Task = () => import('../components/task/Task')
const Lottery = () => import('../components/task/Lottery')
const Red = () => import('../components/task/Red')
const Hunt = () => import('../components/task/Hunt')
const Share = () => import('../components/task/Share')
const Customer = () => import('../components/customer/Customer')
const Knowledge = () => import('../components/customer/Knowledge')
const Refund = () => import('../components/order/Refund')
const RefundList = () => import('../components/order/refundChildComponents/RefundList')
const RefundDetail = () => import('../components/order/refundChildComponents/RefundDetail')
const Notify = () => import('../components/system/Notify')
const Config = () => import('../components/system/Config')
const Supplier = () => import('../components/supply/Supplier')
const Sync = () => import('../components/supply/Sync')
const Brand = () => import('../components/supply/Brand')
const SupplyCategory = () => import('../components/supply/Category')
const FailedDetail = () => import('../components/supply/syncChildComponents/FailedDetail')
const WithdrawSetting = () => import('../components/withdraw/Setting')
const WithdrawList = () => import('../components/withdraw/List')
const WithdrawDetail = () => import('../components/withdraw/Detail')
const Price = () => import('../components/goods/Price')
const LotteryRecordDetail = () => import('../components/task/lotteryChildComponents/LotteryRecordDetail')
const RedRecordDetail = () => import('../components/task/redChildComponents/RedRecordDetail')
const HuntConfig = () => import('../components/task/huntChildComponents/HuntConfig')
const Market = () => import('../components/market/Market')
const PageDefine = () => import('../components/market/pageChildComponents/PageDefine')
const PageShow = () => import('../components/market/pageChildComponents/PageShow')
const AdGoods = () => import('../components/market/pageChildComponents/AdGoods')
const StatUser = () => import('../components/statistics/StatUser')
const StatLottery = () => import('../components/statistics/StatLottery')
const StatShare = () => import('../components/statistics/StatShare')
const InviteAuditRecord = () => import('../components/task/inviteChildComponents/InviteRecordList')
const InviteAuditDetail = () => import('../components/task/inviteChildComponents/InviteRecordDetail')
const WholeCategory = () => import('../components/goods/categoryChildComponents/WholeCategory')
const SubCategory = () => import('../components/goods/categoryChildComponents/SubCategory')
const GoodsSku = () => import('../components/goods/goodsChildComponents/GoodsSku')
const GoodsCommentSubmit = () => import('../components/goods/goodsChildComponents/GoodsCommentSubmit')
const UserTeamApply = () => import('../components/user/userChildComponents/UserTeamApply')
const UserTeamApplyDetail = () => import('../components/user/userChildComponents/UserTeamApplyDetail')
const CouponList = () => import('../components/market/pageChildComponents/CouponList')
const AddOrUpdateCoupon = () => import('../components/market/pageChildComponents/AddOrUpdateCoupon')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        component: Welcome
      },
      {
        path: '/categories',
        component: Category
      },
      {
        path: '/cnzz',
        component: Cnzz
      },
      {
        path: '/cost',
        component: Cost
      },
      {
        path: '/site',
        component: Site
      },
      {
        path: '/goods',
        component: Goods
      },
      {
        path: '/shop',
        component: Shop
      },
      {
        path: '/price',
        component: Price
      },
      {
        path: '/term',
        component: Term
      },
      {
        path: '/attribute',
        component: Attribute
      },
      {
        path: '/add',
        component: GoodsAdd
      },
      {
        path: '/trades',
        component: Trade
      },
      {
        path: '/express',
        component: ExpDelivery
      },
      {
        path: '/refund',
        component: Refund
      },
      {
        path: '/refunds',
        component: RefundList
      },
      {
        path: '/refundDetail',
        component: RefundDetail
      },
      {
        path: '/orders',
        component: Order
      },
      {
        path: '/users',
        component: User
      },
      {
        path: '/systemUsers',
        component: SystemUser
      },
      {
        path: '/qrCode',
        component: QrCode
      },
      {
        path: '/detail',
        component: OrderDetail
      },
      {
        path: '/delivery',
        component: OrderDelivery
      },
      {
        path: '/real',
        component: Real
      },
      {
        path: '/sign',
        component: Sign
      },
      {
        path: '/task',
        component: Task
      },
      {
        path: '/lottery',
        component: Lottery
      },
      {
        path: '/red',
        component: Red
      },
      {
        path: '/hunt',
        component: Hunt
      },
      {
        path: '/share',
        component: Share
      },
      {
        path: '/customer',
        component: Customer
      },
      {
        path: '/knowledge',
        component: Knowledge
      },
      {
        path: '/orderSetting',
        component: OrderSetting
      },
      {
        path: '/supplier',
        component: Supplier
      },
      {
        path: '/sync',
        component: Sync
      },
      {
        path: '/brand',
        component: Brand
      },
      {
        path: '/supply-category',
        component: SupplyCategory
      },
      {
        path: '/failed',
        component: FailedDetail
      },
      {
        path: '/notify',
        component: Notify
      },
      {
        path: '/config',
        component: Config
      },
      {
        path: '/withdrawSetting',
        component: WithdrawSetting
      },
      {
        path: '/withdrawList',
        component: WithdrawList
      },
      {
        path: '/withdrawDetail',
        component: WithdrawDetail
      },
      {
        path: '/lotteryRecordDetail',
        component: LotteryRecordDetail
      },
      {
        path: '/redRecordDetail',
        component: RedRecordDetail
      },
      {
        path: '/huntConfig',
        component: HuntConfig
      },
      {
        path: '/market',
        component: Market
      },
      {
        path: '/pageDefine',
        component: PageDefine
      },
      {
        path: '/pageShow',
        component: PageShow
      },
      {
        path: '/adGoods',
        component: AdGoods
      },
      {
        path: '/statUser',
        component: StatUser
      },
      {
        path: '/statLottery',
        component: StatLottery
      },
      {
        path: '/statShare',
        component: StatShare
      },
      {
        path: '/goodsComment',
        component: GoodsComment
      },
      {
        path: '/inviteAuditRecord',
        component: InviteAuditRecord
      },
      {
        path: '/inviteAuditDetail',
        component: InviteAuditDetail
      },
      {
        path: '/wholeCategory',
        component: WholeCategory
      },
      {
        path: '/subCategory',
        component: SubCategory
      },
      {
        path: '/goodsSku',
        component: GoodsSku
      },
      {
        path: '/commentSubmit',
        component: GoodsCommentSubmit
      },
      {
        path: '/userTeamApply',
        component: UserTeamApply
      },
      {
        path: '/userTeamApplyDetail',
        component: UserTeamApplyDetail
      },
      {
        path: '/couponList',
        component: CouponList
      },
      {
        path: '/addOrUpdateCoupon',
        component: AddOrUpdateCoupon
      }
      /* {
        path: '/categories',
        component: Category
      },
      {
        path: '/params',
        component: Params
      },
      {
        path: '/goods',
        component: GoodsList
      },
      {
        path: '/add',
        component: GoodsAdd
      },
      {
        path: '/reports',
        component: Report
      } */
    ]
  }
]

const router = new VueRouter({
  routes
})

// 添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 如果访问的是登录页，直接放行
  if (to.path === '/login') return next()
  // 从sessionStorage中取出token值
  const token = sessionStorage.getItem('token')
  // 如果token不存在，说明没有登录，就强制跳转到登录页
  if (!token) {
    return next('/login')
  }
  next()
})

export default router
